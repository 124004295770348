import React from "react";
import styled from "styled-components";
import withLayout from "../../layout";
import {FormattedMessage, injectIntl} from "react-intl";
//COMPONENTS
import SimpleStats from "../../components/UI/SimpleStats";
//GENERIC STYLE IMPORT
import { Heading, Paragraph } from "../../components/UI/Typography";
import DropBottom from '../../images/case_studies/acea/DropBottom.svg';
import DropUp from '../../images/case_studies/acea/DropUp.svg';
import BKGImg from "../../images/case_studies/acea/banner-acea-provvisorio.jpg";
import HeroCaseStudy from "../../components/HeroCaseStudy";
import LottieController from "../../components/UI/LottieController";
import Graph1 from '../../images/case_studies/acea/LittleGlobe.json';
import Graph2 from '../../components/UI/Lottie/Colleghi.json';
import { useInView } from "react-intersection-observer";
import DropBg from "../../components/UI/DropBg";
import Video1 from '../../images/case_studies/acea/minivideo-acea.mp4';
import VideoLaptop from '../../images/case_studies/acea/laptop acea.mp4';
import Drop from '../../images/case_studies/acea/Drop.jpg';
import DropBig1 from '../../images/case_studies/acea/DropBig1.svg';
import DropBig2 from '../../images/case_studies/acea/DropTopLeftM.svg';
import SEO from "../../components/SEO";
import LutherLogo from "../../images/case_studies/enel/luther_logo.svg";
import {Fade} from 'react-reveal';
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage";
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory";
import SectionTitle from "../../components/CaseStudy/SectionTitle";
import Results from "../../components/CaseStudy/Results";
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"

const StatSection = styled.div`
padding-top:32px;
h1{
font-weight: bold;
font-size: 80px;
letter-spacing: 2.9px;
color:#1D0F41;
}
h2{
font-weight: bold;
font-size: 50px;
letter-spacing: 1.82px;
color: #2266A4;
}
p{
    font-weight:bold;
    font-size:18px
    letter-spacing:0.39px;
    color: #2266A4;

}
`

const StatsDiv = styled.div`
    margin-top: 32px;
    h3{
    color:#1D0F41;
    font-weight:700;
    font-style:normal;
    font-size:32px;
    margin:0px;
    }
    p{
        color:#2266A4;
        font-size:18px;
        font-weight:700;
    }
`

const AceaCaseStudy = ({intl}) => {
    const [graphView1, inViewG1] = useInView();
    const [graphView3, inViewG3] = useInView();
    const keywords = intl.formatMessage({id:'acea.meta_keywords'}).split(',') || [];

    return (
        <>
            <SEO
                title={intl.formatMessage({id: 'acea.meta_title'})}
                description={intl.formatMessage({id: 'acea.meta_description'})}
                keywords={keywords}
                lang={intl.locale}
            />

            <HeroCaseStudy
                hasPartner
                partnerLogo={LutherLogo}
                heroImage="case_studies/acea/header-acea.png"
                title="acea.title"
                stats={<CompanyStats/>}
                subtitle1="acea.subtitle"
                subtitle2="acea.subtitle_2"
                color="linear-gradient(90deg, #165B97 24.51%, #2DD78C 77.14%, #FFFF00 100%)"
                categories={['portfolio.filters.design_dev']}
                intl={intl}
            />
            <div className="section-padding">
                <DropBg src={DropBig1} left={'0%'} zIndex={'-1'} bottom={'-20%'} className="d-none d-lg-block" />
                <DropBg src={DropUp} bottom={'5%'} left={'25%'} className="d-none d-lg-block" />
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <Fade bottom delay={200}>
                                <div className="col-12">
                                    <SectionTitle number={'01'} text={'acea.overview_title'}/>
                                </div>
                            </Fade>
                        </div>
                        <div className="row">
                            <div className={"col-md-5 col-12"}>
                                <Fade bottom delay={300}>
                                    <Heading level={"3"}>
                                        <FormattedMessage id={"acea.client_title"} />
                                    </Heading>
                                </Fade>
                                <Fade bottom delay={500}>
                                    <Paragraph className="pt-2">
                                        <FormattedMessage id={"acea.client_1"} />
                                    </Paragraph>
                                    <StatSection>
                                        <h1>3,186</h1>
                                        <h2><FormattedMessage id={'acea.sales_1'} /></h2>
                                        <p><FormattedMessage id={'acea.sales_2'} /></p>
                                    </StatSection>
                                </Fade>
                            </div>
                            <div className={"col-md-6 col-12 offset-md-1"}>
                                <Fade bottom delay={400}>
                                    <Heading level={"4"} className="pt-2">
                                        <FormattedMessage id={"acea.client_needs"} />
                                    </Heading>
                                </Fade>
                                <Fade bottom delay={600}>
                                    <Paragraph className="pt-3">
                                        <FormattedMessage id={"acea.client_needs_1"} />
                                    </Paragraph>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <FullWidthImage image={BKGImg} imageWidth={1919} imageHeight={700}/>
            <div className="section-padding">
                <DropBg src={DropBig2} right={'-10%'} zIndex={'-1'} top={'15%'} className="d-none d-lg-block" />
                <DropBg src={DropBottom} top={'10%'} right={'10%'} className="d-none d-lg-block" />
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 d-none d-md-block pl-5">
                                <SectionTitle number={'02'} text={'acea.brief_title'}/>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <Heading level={"2"} margin={'0px'}>
                                    <FormattedMessage id={"acea.service_big_title"} />
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"acea.services_txt_1"} />
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className="section-padding-custom-md d-flex align-items-center">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-6 col-sm-12">
                            <Fade bottom delay={400}>
                                <SectionTitle number={'03'} text={'acea.workflow_title'}/>
                            </Fade>
                            <Fade bottom delay={800}>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"acea.solutions_big_title"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id="acea.solutions_txt_1"/>
                                </Paragraph>
                            </Fade>
                        </div>
                        <div className="col-md-5 offset-md-1 col-sm-12"
                             dangerouslySetInnerHTML={{
                                 __html: `
                                    <div class="video-wrapper">
                                        <img src="${Drop}" alt="drop"/>
                                        <video
                                            loop
                                            muted
                                            autoplay
                                            playsinline
                                            webkit-playsinline
                                            src="${Video1}"
                                        />
                                    </div>
                                `
                             }}
                        />
                    </div>
                </div>
            </div>
            <div className="section-padding">
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-sm-12 order-last order-md-first"
                                dangerouslySetInnerHTML={{
                                    __html: `<video  loop muted autoplay  playsinline
                                    class="video-section"
                                 webkit-playsinline
                                 src="${VideoLaptop}"
                               />`}}
                            >
                            </div>
                            <div className="col-md-6 col-12 col-sm-12 order-first order-md-last">
                                <Paragraph className="pt-2 pr-md-5">
                                    <FormattedMessage id={"acea.solutions_txt_2"} />
                                </Paragraph>
                            </div>

                        </div>
                    </div>
                </Fade>
            </div>
            <Results
                subtitle="acea.dark_section_paragraph"
                testimonialQuote="acea.dark_section_quote"
                testimonialImg="carlo_angeletti.jpg"
                testimonialName="acea.dark_section_testimonial_name"
                testimonialRole="acea.dark_section_testimonial_desc"
            >
                <div className="col-12 col-lg-3">
                    <div ref={graphView1}>
                        <LottieController
                            lottieJSON={Graph1}
                            inView={inViewG1}
                            height="100px"
                            width="86px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG1}
                            valueFrom={0}
                            valueTo={25}
                            headingStyle={{fontWeight: 500}}
                            label="acea.stats_1"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView3}>
                        <LottieController
                            lottieJSON={Graph2}
                            inView={inViewG3}
                            height="100px"
                            width="122px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG3}
                            valueFrom={0}
                            valueTo={1500}
                            label="acea.stats_2"
                            headingLevel="1"
                            labelColor="white"
                            headingStyle={{fontWeight: 500}}
                        />
                    </div>
                </div>
            </Results>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="acea.next_success_case_title"
                desc="acea.next_success_case_description"
                link="/case-study/jti/"
                intl={intl}
            />
        </>
    )
}

const customProps = {
    localeKey: "acea", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(AceaCaseStudy))

const CompanyStats = () => {
    return <StatsDiv>
        <h3>
            7576+
        </h3>
        <p>
            <FormattedMessage id="acea.stats_1_msg"/>
        </p>
    </StatsDiv>
}
